@import 'main.scss';
.duoBG {
  position: absolute;
  top: 0;
  left: 0;
  pointer-events: none;
  background-color: #17082f;
  width: 100%;
  height: 100%;
}

.duoContainer {
  width: 100%;
  height: 100vh;
  position: relative;
  z-index: 21;
  padding-top: 100px;

  @media screen and (min-width: 601px) {
    padding-top: 0;
    height: 175vh;
  }

  .canvas {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 115vh;
    pointer-events: none;

    @media screen and (min-width: 1000px) {
      height: 125vh;
    }
  }

  .bodyContainer {
    height: 125vh;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    position: relative;

    .textContainer {
      margin-top: 50px;
      position: absolute;
      z-index: 11;
      display: flex;
      flex-direction: column;
      align-items: flex-start;

      @media screen and (min-width: 601px) {
        margin-top: 150px;
        margin-top: 430px;
      }

      h2 {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        color: var(--primary);

        span {
          -webkit-text-stroke-width: 1px;

          @media screen and (min-width: 1000px) {
            -webkit-text-stroke-width: 2px;
          }
        }

        :first-child {
          color: transparent;
          background-image: linear-gradient(
            -90deg,
            rgba(255, 255, 255, 0.5) 50%,
            var(--white) 50%
          );
          padding-right: 5px;
        }

        :last-child {
          -webkit-text-stroke: 1px var(--white);
          paint-order: stroke fill;
          color: transparent;

          @media screen and (min-width: 1200px) {
            -webkit-text-stroke: 3px var(--white);
          }
        }
      }

      .description {
        color: var(--white);
        font-family: var(--font-sora);
        max-width: 352px;
        font-size: 16px;
        font-weight: 300;
        line-height: 22px;
        text-align: left;
        margin-top: 32px;

        @media screen and (min-width: 1001px) {
          max-width: 500px;
          font-size: 20px;
          line-height: 26px;
          margin-top: 40px;
        }
      }
    }
  }
}
