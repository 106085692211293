@import 'main.scss';
.servicesContainer {
  width: 100%;
  font-family: var(--font-sora);
  margin-top: 150px;
  padding-bottom: 34px;
  overflow: hidden;

  @media screen and (min-width: 1000px) {
    padding-bottom: 150px;
  }

  @media screen and (min-width: 1200px) {
    height: 162vh;
  }

  @media screen and (min-width: 1380px) {
    height: auto;
  }

  h2 {
    max-width: 1000px;
    margin: 0 auto;
    padding-bottom: 50px;
    color: var(--primary);
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;

    @media screen and (min-width: 1000px) {
      padding-bottom: 70px;
    }

    @media screen and (min-width: 1600px) {
      padding-bottom: 50px;
    }

    :first-child {
      width: 100%;
      display: flex;
      justify-content: center;
    }

    .lastWords {
      display: flex;
      align-items: center;

      :first-child {
        padding-left: 5px;

        &::after {
          content: "\2005";
        }
      }
    }
  }
}
