@import 'main.scss';
.sectionContainer {
  height: auto;
  width: 100%;
  position: relative;
  background-color: var(--primary);
  overflow: hidden;

  padding-top: 50vh;

  .duckScene {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    bottom: 80px;
    z-index: 5;
  }

  .imagesTextContainer {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .textContainer {
      display: flex;
      flex-direction: column;
      padding-top: 120px;

      h2 {
        display: flex;
        flex-direction: column;

        :first-child {
          color: transparent;
          background-image: linear-gradient(
            -90deg,
            rgba(255, 255, 255, 0.5) 50%,
            var(--white) 50%
          );
          padding-right: 6px;
        }

        :last-child {
          -webkit-text-stroke: 3px var(--white);
          paint-order: stroke fill;
          color: var(--primary);

          @media screen and (min-width: 1000px) {
            -webkit-text-stroke: 5px var(--white);
          }

          @media screen and (min-width: 1200px) {
            -webkit-text-stroke: 6px var(--white);
          }
        }
      }

      .paragraph {
        margin-top: 24px;
        color: white;
        font-family: var(--font-sora);
        font-size: 16px;
        font-style: normal;
        font-weight: 300;
        line-height: 22px;
        max-width: 500px;

        @media screen and (min-width: 1000px) {
          max-width: 650px;
          font-size: 20px;
          line-height: 28px;
        }

        @media screen and (min-width: 1200px) {
          max-width: 930px;
        }
      }
    }

    .carrouselContainer {
      width: 100%;
      position: relative;
      overflow: hidden;
      padding-top: 140px;
      padding-bottom: 236px;

      @media screen and (min-width: 669px) {
        padding-top: 93px;
        padding-bottom: 246px;
      }

      @media screen and (min-width: 1025px) {
        padding-top: 83px;
        padding-bottom: 307px;
      }

      @media screen and (min-width: 1201px) {
        padding-top: 110px;
        padding-bottom: 413px;
      }

      .carrouselTopCircle,
      .carrouselBottomCircle {
        position: absolute;
        top: 50%;
        left: 50%;
        width: 977px;
        height: 216px;
        background-color: var(--primary);
        border-radius: 100%;
        z-index: 5;

        @media screen and (min-width: 669px) {
          width: 1572px;
          height: 281px;
        }

        @media screen and (min-width: 1025px) {
          width: 2871px;
          height: 473px;
        }

        @media screen and (min-width: 1901px) {
          width: 3019px;
        }
      }

      .carrouselTopCircle {
        transform: translate(-50%, -168%);

        @media screen and (min-width: 669px) {
          transform: translate(-50%, -170%);
        }

        @media screen and (min-width: 1025px) {
          transform: translate(-50%, -154%);
        }

        @media screen and (min-width: 1201px) {
          transform: translate(-50%, -160%);
        }
      }

      .carrouselBottomCircle {
        transform: translate(-50%, 13%);

        @media screen and (min-width: 669px) {
          transform: translate(-50%, 5%);
        }

        @media screen and (min-width: 1025px) {
          transform: translate(-50%, 0%);
        }

        @media screen and (min-width: 1201px) {
          transform: translate(-50%, -5%);
        }

        @media screen and (min-width: 1901px) {
          transform: translate(-50%, -1%);
        }
      }

      .carrouselWrapper {
        position: relative;
        width: 238px;
        height: 170px;
        margin: 40px auto;
        perspective: 1000px;

        @media screen and (min-width: 1025px) {
          width: 363px;
          height: 259px;
        }

        @media screen and (min-width: 1201px) {
          width: 452px;
          height: 323px;
        }

        .carrouselContent {
          width: 100%;
          height: 100%;
          position: absolute;
          transform-style: preserve-3d;

          .carrouselItemWrapper {
            width: 100%;
            height: 100%;
            position: absolute;
            transform-style: preserve-3d;
            transform-origin: center center;
            animation: carrouselMovement 80s infinite linear;

            .carrouselItem {
              position: absolute;
              width: 238px;
              height: 170px;
              overflow: hidden;
              border-radius: 6.61px;

              @media screen and (min-width: 1025px) {
                width: 363px;
                height: 259px;
              }

              @media screen and (min-width: 1201px) {
                width: 452px;
                height: 323px;
              }

              picture {
                width: 100%;
                height: 100%;
                position: relative;

                img {
                  position: absolute;
                  width: 100%;
                  height: 100%;
                  object-fit: cover;
                  object-position: center center;
                }
              }
            }
          }
        }
      }
    }
  }
}

@keyframes carrouselMovement {
  0% {
    transform: rotateY(0deg);
  }

  100% {
    transform: rotateY(360deg);
  }
}
