@import 'main.scss';
.transitionContainer {
  user-select: none;

  width: 100vw;
  height: 200vh;

  background-color: transparent;

  overflow: hidden;

  position: absolute;

  z-index: 22;

  top: 0;
  left: 0;

  .transitionBgForward {
    min-width: 100vw;
    min-height: 100vh;

    width: 100%;
    height: 100%;

    background-color: #fbf5ff;
  }

  .logoContainer {
    position: absolute;

    top: 0%;

    width: 100vw;
    height: 100vh;

    display: flex;
    align-items: center;
    justify-content: center;
    @media screen and (max-width: 1000px) {
      transform: scale(0.75);

      &.heightLogo {
        height: 70vh;
      }
    }
    svg:nth-child(1) {
      user-select: none;
      pointer-events: none;

      transform: translate(-50%, 50%);
      position: absolute;
    }

    svg:nth-child(2) {
      user-select: none;
      pointer-events: none;

      position: absolute;
      transform: translate(50%, 750%);
      animation: 0.5s infinite alternate blink;
    }

    @keyframes blink {
      0% {
        opacity: 1;
      }

      50% {
        opacity: 1;
      }

      51% {
        opacity: 0;
      }

      100% {
        opacity: 0;
      }
    }
  }
}
